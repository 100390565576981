import React from "react";
import fifthBlockImg from "../../assets/images/RAP.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FifthBlock = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <section className="fifthblock" ref={ref}>
      <div className="container">
        {inView && (
          <>
            <div className="fifthblock__wrapper">
              <motion.aside
                className="fifthblock__left"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <h2>О ПРОЕКТЕ</h2>
                <div className="text-wrap-yellow">
                  <p>
                  В 21 веке, когда стало возможным «переписывать историю», очень важно сохранить истину, научить подрастающее
                   поколение гордиться и чтить память своей Родины, свои культурные ценности и национальные традиции.
                   </p>
                   
                   
                    <p> Знакомство
                    с историей, историческими личностями и посвящённым им творчеством в России <span className="boldText"> при использовании виртуальной среды </span> 
                     – это новый, своевременный, широкодоступный, а главное <span className="boldText"> увлекательный формат</span>. Это поможет повысить качественный 
                     уровень изучения истории и культуры России среди молодежи. А более взрослому поколению наверняка будет интересно
                      увидеть личность Петра 1 и посвященное ему выдающееся произведение монументальной скульптуры в виртуальной реальности
                       и вместе со своими детьми, открывать и видеть новое в творчестве России.
                       </p>
                       
                       
                       <p> Настоящий Проект реализуется в рамках грантовой поддержки Министерства культуры РФ и Соглашения о 
                        творческом и научно-практическом сотрудничестве, заключенным между Авторским Союзом «БОНА ФИДЭС» и 
                         Южным отделением Российской Академии Художеств, <span className="boldText"> в целях развития творческого, образовательного и
                          научного сотрудничества </span>  на основе взаимных интересов в сфере образования, культуры и искусства.

                          В этом Проекте начата самая креативная реализация  Приоритетных направлений фундаментальных и поисковых исследований 
                          на 2021-2030 годы, принятых общим собранием Российской Академией Художеств. <span className="boldText"> Реализацией настоящего Проекта стала возможность,
                           впервые в России, бесплатного доступа к блокчейн-платформам </span>  и маркетплейсам с получением NFT-токена  произведения 
                           графического дизайна – Памятник «В ознаменование 300-летия Российского Флота» неограниченному количеству заинтересованных граждан.
  
                  </p>
                </div>
              </motion.aside>
              <motion.aside
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                className="fifthblock__right"
              >
                <img src={fifthBlockImg} alt="fifth block" />
              </motion.aside>
            </div>

            {/* <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti
              eius error explicabo eligendi vitae illo mollitia magni in
              quisquam id recusandae vel sequi consequuntur pariatur inventore
              perferendis fugiat animi, delectus doloremque voluptatibus
              excepturi, esse velit quod sit! Accusantium cupiditate ducimus
              beatae voluptatem harum placeat mollitia consequuntur! Voluptas
              nisi porro quos. Voluptatibus, asperiores error magni alias
              deleniti quisquam animi sequi fugit ipsam, harum velit sapiente
              ullam, numquam culpa facilis aperiam cumque. Facilis dolorem,
              nesciunt eos perferendis perspiciatis quibusdam quas quia ipsa
              soluta cupiditate error inventore tempora provident omnis fugiat
              ipsum harum cumque cum itaque labore, similique rem! Voluptatibus
              praesentium eveniet cupiditate, distinctio recusandae doloremque
              in quaerat nulla tempora! Quas, laborum. Blanditiis expedita nulla
              consectetur aliquam incidunt voluptatibus, aperiam adipisci rem
              est reiciendis, temporibus laboriosam doloribus ullam sit, id
              earum neque amet? Molestiae fugit voluptas necessitatibus quas
              laudantium, quos fugiat sequi dolore ex ea dolorem velit error
              nulla asperiores, esse optio pariatur reprehenderit quibusdam!
              Culpa eveniet quae cupiditate deleniti provident saepe ab
              repellendus quia officia distinctio impedit eos minima, quam
              corporis, recusandae dolor maxime iusto non, magni quas alias
              dolorum. Ducimus dignissimos laborum expedita repudiandae
              necessitatibus sint odit dolorum a tempora iusto voluptatem
              architecto adipisci quod similique numquam nostrum earum, commodi
              fugit.
            </p> */}
          </>
        )}
      </div>
    </section>
  );
};

export default FifthBlock;
