import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

const projectId = "198caf624b6ddc452dc34e8de5bf83f3";

const config = getDefaultConfig({
  appName: "App",
  projectId: projectId,
  chains: [mainnet],
});

export const Providers = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider locale="ru">{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
