import React from "react";
import fifthBlockImg from "../../assets/images/flag.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FifthBlock = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <section className="fifthblock history" ref={ref}>
      <div className="container">


        {inView && (
          <>
            <div className="fifthblock__wrapper">
              <motion.aside
                className="fifthblock__left"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <h2>Историческая справка</h2>

                <div className="text-wrap-yellow">

                  <p >

                  <p className="boldText">Днём рождения регулярного Российского Флота принято считать 30 октября 1696 года.</p>


                   В тот день Боярская дума и царь Пётр I приняли постановление о создании российского флота, констатировав: «Морским судам быть».
  Созданный в Русском царстве в эпоху правления Петра I 
   Военно-морской флот — военно-морские силы (армейский флот) заложил основу  Российского императорского флота.
  
                  </p>
  
                    <p>
  
                    <p className="boldText">С выходом к Балтике Пётр I утверждает новый государственный флаг.</p>

                    Созданный на основе немецкой расцветки, он становится 
                    «имперским» символом России. На жёлтом полотнище изображён двуглавый чёрный орёл, держащий морские карты с 
                    изображением Белого, Каспийского и Азовского морей. Вот собственноручное описание Петра: «Штандарт, черный орел 
                    в желтом поле, яко Герб Российской империи, имея три короны: две королевских и одну Империальскую, в которого грудях св.
                     Георгий с драконом. В обеих же главах и ногах 4 карты морских: в правой главе Белое море, в левой Каспийское, в
                      правой ноге Палас Меотис (Азовское море), в левой Синус Финикус (Финский залив) и пол Синуса Ботника (Ботанический залив) и часть Ост-Зее (Балтийское море)».
                    </p>

                    </div>
              </motion.aside>
              <motion.aside
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                className="fifthblock__right"
              >
                <img src={fifthBlockImg} alt="fifth block" />
              </motion.aside>
            </div>

            <p>
            <p className="boldText">Основываясь на опыте античных воителей и своих предшественников,
             Петр I сумел создать уникальное по своим возможностям явление,
              которое аккумулировало в себе возможности мобильной армии, 
              передвигающейся на лёгких гребных судах.</p>

             В отличие от других
               морских держав того времени, например, Швеции, в которой шхерные эскадры 
               представляли собой небольшие формирования судов для действия на мелководье и 
               проведения небольших десантных операций и подчинялись непосредственно адмиралам 
               корабельного (батарейного) флота, Армейский флот Петра действовал самостоятельно 
               и очень удачно в благоприятных для этого условиях Балтийского моря. Деятельность 
               Петра I по созданию и развитию русского флота сыграла большую роль в возвращении
                Россией выходов к Азовскому, Балтийскому,  Каспийскому и Чёрному морям.
            </p>
          </>
        )}
      </div>
    </section>
  );
};

export default FifthBlock;
