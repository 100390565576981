import React, { useEffect, useState } from "react";
import rightBlock from "../../assets/images/peter1-secondpage.jpg";

const SecondBlock = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(scrollPosition);

  return (
    <section className="secondblock">
      <div className="container">
        <div className="secondblock__wrapper">
          <aside className="secondblock__left">
            <h2 className={`box ${scrollPosition > 2400 ? "slide-right" : ""}`}>
              Биография Петра I
            </h2>
            <div className="text-wrap-yellow">
              <p className={`box ${scrollPosition > 2400 ? "slide-right" : ""}`}>
              В российской историографии Петра 1 принято считать 
              
              <span className="boldText"> одним из наиболее выдающихся 
              государственных деятелей, </span> 
              
              
              определившим направление развития России в XVIII веке.
  Пётр 1 первым из русских царей совершил длительное путешествие в страны Западной Европы. По возвращении
   из него, в 1698 году, <span className="boldText">Пётр развернул масштабные реформы российского государства</span>  и общественного уклада.
    Одной из заслуг Петра стало расширение территорий России в Прибалтийском регионе после победы в Великой 
    Северной войне, что позволило ему принять в 1721 году <span className="boldText">титул российского императора</span> 
  
              </p>
  
              <p  className={`box ${scrollPosition > 2500 ? "slide-right" : ""}`}>
              Петр I содействовал подъему производительных сил страны, поощрял развитие отечественных мануфактур,
               путей сообщения, внутренней и внешней торговли.
               
               <span className="boldText"> Реформы государственного аппарата при Петре I
                явились важным шагом 
                </span> <span> </span>
                
                
                 на пути превращения русского самодержавия XVII века в чиновничье-дворянскую 
                монархию XVIII века с ее бюрократией и служилыми сословиями. Уделяя много внимания техническому
                 перевооружению армии и флота, Петр I наладил разработку и производство новых типов кораблей, 
                 новых образцов артиллерийских орудий и боеприпасов, создал стройную систему базирования флота
                  на Азовском, Балтийском и Каспийском морях. Было построено большое количество гребных и парусных судов.
              </p>
  
              <p className={`box ${scrollPosition > 2600 ? "slide-right" : ""}`}>
              Петром 1 была проведена реформа государственного управления, преобразования в 
              армии, был создан военный флот, была осуществлена реформа церковного управления 
              в духе цезаропапизма, направленная на ликвидацию автономной от государства церковной
               юрисдикции и подчинение российской церковной иерархии императору. Также была осуществлена 
               финансовая реформа, предпринимались мероприятия по развитию промышленности и торговли.
              В 1703 году Петр I заложил город Санкт-Петербург, ставший в 1712 году столицей
               государства.  <span className="boldText">В 1721 году Россия была провозглашена империей.</span> 
              </p>
            </div>
          </aside>
          <aside className="secondblock__right">
            <img src={rightBlock} alt="second" />
          </aside>
        </div>
      </div>
    </section>
  );
};

export default SecondBlock;
