import React from "react";
import telegram from "../../assets/icons/telegram.svg";
import rutube from "../../assets/icons/rutube.svg";
import vk from "../../assets/icons/vk.svg";
import ok from "../../assets/icons/ok.svg";
import founderImg1 from "../../assets/icons/rah1.png";
import founderImg2 from "../../assets/icons/moasd.png";
import founderImg3 from "../../assets/icons/tan.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <aside className="footer__left">
            {/* <div className="footer__nav">
              <p>Навигация</p>
              <nav>
                <ul>
                  <li>
                    <a href="#">Кнопка</a>
                  </li>

                  <li>
                    <a href="#">Кнопка</a>
                  </li>

                  <li>
                    <a href="#">Кнопка</a>
                  </li>

                  <li>
                    <a href="#">Кнопка</a>
                  </li>

                  <li>
                    <a href="#">Кнопка</a>
                  </li>
                </ul>
              </nav>
            </div> */}

            <div className="footer__socials">
              <p>Мы в соцсетях</p>
              <ul>
                <li>
                  <a target="_blank" href="https://t.me/authors_unionbf">
                    <img src={telegram} alt="telegram" />
                  </a>
                </li>

                {/* <li>
                  <a href="#">
                    <img src={rutube} alt="telegram" />
                  </a>
                </li> */}

                <li>
                  <a target="_blank" href="https://vk.com/authors_union">
                    <img src={vk} alt="telegram" />
                  </a>
                </li>

                <li>
                  <a target="_blank" href="https://ok.ru/group/70000007683972">
                    <img src={ok} alt="telegram" />
                  </a>
                </li>
              </ul>
            </div>
          </aside>
          <aside className="footer__right">
            <p>Контакты</p>
            <a href="tel:+74951000000">+7(928) 610-93-39</a>
            <a href="mailto:info@project.center">authors-union@yandex.ru</a>
          </aside>
        </div>

        <div className="footer__founders">

          {/* <div>
          <img src={founderImg1} alt="founder 1" />

          </div>

          <img src={founderImg2} alt="founder 2" />

        <div>

        </div>


        <div>
        <img src={founderImg3} alt="founder 3" />


        </div> */}


          <ul>
            <li>
            <a href="https://rah.ru/" target="_blank" style={{cursor:"pointer"}}><img src={founderImg1} alt="founder 1" /></a>
            </li> 


             <li>
             <a href="https://www.moasd.ru/" target="_blank" style={{cursor:"pointer"}} ><img src={founderImg2} alt="founder 2" /></a>
            </li> 

            <li>
              <a href="https://creative-coin.com" target="_blank" style={{cursor:"pointer"}} ><img src={founderImg3} alt="founder 3" /></a>
            </li>

            {/* <li>
              <img src={founderImg} alt="founder 4" />
            </li>

            <li>
              <img src={founderImg} alt="founder 5" />
            </li> */}
          </ul>
        </div>

        <div className="footer__copyright">
          <p>Свидетельство Минюста РФ № 6112012443 от 13.04.2012</p>

<br />
<p>Использование материалов сайта без согласия Правообладателей недопустимо!</p>
<br />
<p>© 2023 - 2024 || Genius and software engineer, style & design Artem L & Co</p>
<p>© 2023 - 2024 || Authors Union “BONA FIDES”</p>

          {/* <ul>
            <li>
              <a href="#">Политика конфеденциальности</a>
            </li>

            <li>
              <a href="#">Политика в отношении обработки персональных данных</a>
            </li>
          </ul> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
