import React, { useState } from "react";
import "./Carousel.scss";

import image1 from '../../assets/carouselPNG/w1.png';
import image2 from '../../assets/carouselPNG/w2.png';
import image3 from '../../assets/carouselPNG/w3.png';
import image4 from '../../assets/carouselPNG/w4.png';
import image5 from '../../assets/carouselPNG/w5.png';
import image6 from '../../assets/carouselPNG/w6.png';
import image7 from '../../assets/carouselPNG/w7.png';
import image8 from '../../assets/carouselPNG/w8.png';
import image9 from '../../assets/carouselPNG/w9.png';
import image10 from '../../assets/carouselPNG/w10.png';

const images = [
  image1,image2,image3,image4,image5,image6,image7,image8,image9,image10
];

export const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (

    <div className="carousel">
        
      <button className="carousel-button prev" onClick={goToPrev}>
        &#171;
      </button>
      <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} />
      <button className="carousel-button next" onClick={goToNext}>
        &#187;
      </button>
    </div>
  );
};