import React, { useEffect, useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

import fourthBlockImg from "../../assets/images/peter1.gif";
import { useMintWrite } from "../../hooks/useContractWrite";
import { Carousel } from "./Carousel"; // Импортируем новый компонент

const FourthBlock = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { address, status, chain } = useAccount();
  const { write } = useMintWrite({ addressTo: address });

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="fourthblock">
      <div className="container">
        <div className="fourthblock__wrapper">
          <aside className="fourthblock__left">
            <img src={fourthBlockImg} alt="fourthblock" />
            {status === "connected" && chain ? (
              <button className="mintButton" onClick={write}>Получить NFT</button>
            ) : (
              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openChainModal,
                  openConnectModal,
                  mounted,
                }) => {
                  const ready = mounted;
                  const connected = ready && account && chain;

                  return (
                    <div
                      {...(!ready && {
                        "aria-hidden": true,
                        style: {
                          opacity: 0,
                          pointerEvents: "none",
                          userSelect: "none",
                        },
                      })}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <button className="mintButton" onClick={openConnectModal} type="button">
                              Получить NFT
                            </button>
                          );
                        }

                        if (chain.unsupported) {
                          return (
                            <button className="mintButton" onClick={openChainModal} type="button">
                              Получить NFT
                            </button>
                          );
                        }
                      })()}
                    </div>
                  );
                }}
              </ConnectButton.Custom>
            )}
          </aside>
          <aside className="fourthblock__right">
            <h2
              className={`box2 ${scrollPosition > 5900 ? "slide-right2" : ""}`}
            >
              Станьте обладателем коллекционного NFT
            </h2>
            <div className="text-wrap-yellow">

            <p
              className={`box2 ${scrollPosition > 6000 ? "slide-right2" : ""}`}
            >
              В рамках проекта, который выполняется в честь 300-летия российского флота,
               граждане России теперь имеют возможность бесплатного доступа к блокчейн-платформам и маркетплейсам,
                а также получения NFT-токена цифровой версии памятника "В ознаменование 300-летия российского флота".

                <span> </span><span className="boldText">Это первый случай в России</span>

               ,<span> </span>когда прошлое тесно взаимодействует с будущим, используя современные 

                технологии блокчейн для создания нового культурного наследия, которое останется навечно в истории.
            </p>

            <p
              className={`box2 ${scrollPosition > 6100 ? "slide-right2" : ""}`}
            >
             NFT (невзаимозаменяемые токены) - это уникальные цифровые активы, которые могут быть использованы для подтверждения и легитимизации собственности на цифровое искусство, музыку, видео и другие цифровые активы. Благодаря блокчейну, NFT могут быть созданы и проданы на специализированных платформах, таких как OpenSea, Blur и другие.

            </p>

            <p
              className={`box2 ${scrollPosition > 6200 ? "slide-right2" : ""}`}
            >
              Технология блокчейн позволяет создавать информационно-экономические системы, объединяя всех участников развивающегося рынка интернета вещей. Это позволяет графическим дизайнерам создавать уникальные и визуально привлекательные цифровые произведения искусства, которые могут быть проданы как NFT на различных торговых площадках.
            </p>

            </div>

            <ol
              className={`box2 ${scrollPosition > 6300 ? "slide-right2" : ""}`}
            >
              <p style={{color:"#ffc21a"}}>
              Перед получением NFT удостоверьтесь, что имеете достаточно криптовалюты для оплаты газа сети (около 500 рублей в Ethereum )
              </p>
            </ol>
            {/* <p >Инструкция по созданию крипто-кошелька</p> */}
            {/* Вставляем новый компонент Carousel */}
            <Carousel />
          </aside>
        </div>
      </div>
    </section>
  );
};

export default FourthBlock;