import "./styles/App.css";
import "./styles/index.css";
import "./styles/reset.css";
import Header from "./components/header/Header";
import Head from "./components/head/Head";
import SecondBlock from "./components/secondblock/SecondBlock";
import ThirdBlock from "./components/thirdblock/ThirdBlock";
import FourthBlock from "./components/fourthblock/FourthBlock";
import FifthBlock from "./components/fifthblock/FifthBlock";

import SecondBlock1 from "./components/secondblock1/SecondBlock1";
import FifthBlock1 from "./components/fifthblock1/FifthBlock1";
import FifthBlock2 from "./components/fifthblock2/FifthBlock2";

import SecondBlock2 from "./components/secondblock2/SecondBlock2";
import Fourthblock1 from "./components/fourthblock1/FourthBlock1";


import Footer from "./components/footer/Footer";
import { Providers } from "./Providers";
import { useEffect } from "react";

function App() {

  
  const loaderWrap = document.createElement('div')

  const loader = document.createElement('div')

  loaderWrap.classList.add('loader-wrap')

  loader.classList.add('loader')

  loaderWrap.append(loader)

  document.body.append(loaderWrap)

  // eslint-disable-next-line no-undef, react-hooks/rules-of-hooks

  useEffect(() => {

    window.addEventListener('load', () => {

      console.log('load')

      document.querySelectorAll('.loader-wrap').forEach(el => el.remove())

    })

  }, [])




  return (
    <Providers>

    <div className="App">
      {/* <Header /> */}

      <main className="main">
        <Head />
        <FifthBlock1 />          {/* о проекте +*/} 
        <Fourthblock1/>  {/* история памятника + */} 
        <SecondBlock />   {/* Биография Петра 1 + */} 
        <ThirdBlock />   {/* Реформы Петра 1 +  */} 
        <FifthBlock />   {/* Историческая справка */} 

        <FourthBlock />   {/* ПОКУПКА НФТ  */} 
        <FifthBlock2 />   {/* Об авторе памятника */} 

        





      </main>

      <Footer />
    </div>
        </Providers>

  );
}

export default App;
