import React from "react";
import { motion } from "framer-motion";

const Head = () => {
  const text = `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde
  ipsam repellat fugit, sequi pariatur incidunt. Sint laudantium
  quidem doloribus tenetur temporibus eaque veritatis iste quod
  sapiente dignissimos? Sint reprehenderit magni accusantium
  distinctio dolorum alias ducimus, cum tempore doloribus aliquid
  doloremque iusto praesentium ipsa ipsum vitae aut! Cupiditate,
  modi! Animi, tenetur.`.split(" ");

  return (
    <section className="head">
      <div className="container">
        <div className="head__wrapper">
          <motion.div
            className="head__title"
            initial={{ backgroundSize: "100%" }}
            animate={{ backgroundSize: "200%" }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 40,
              repeat: Infinity,
              repeatType: "mirror",
              repeatDelay: 0.2,
            }}
          >
            <h1>Российскому флоту</h1>
            <h1>посвящается</h1>
          </motion.div>

          <div className="head__text">
            <p style={{marginLeft:"5%"}}>
              {/* {text.map((el, i) => (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 0.25,
                    delay: i / 20,
                  }}
                  key={i}
                >
                  {el}{" "}
                </motion.span>
              ))} */}


Проект, посвященный созданию в виртуальном пространстве проекта NFT  с трехмерным видеоизображением монументального памятника «В ознаменование 300-летия российского флота» работы Зураба Церетели для знакомства жителей России других стран произведением искусства, с нашей историей и культурой.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Head;
