import React, { useEffect, useState } from "react";
import fourthBlockImg from "../../assets/images/monument2.jpg";

const FourthBlock = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="fourthblock">
      <div className="container">
        <div className="fourthblock__wrapper">
          <aside className="fourthblock__left">
            <img src={fourthBlockImg} alt="fourthblock" />
          </aside>
          <aside className="fourthblock__right">
            <h2
              className={`box2 ${scrollPosition > 1300 ? "slide-right2" : ""}`}
            >
              История памятника
            </h2>
            <div className="text-wrap-yellow">
              <p
                className={`box2 ${scrollPosition > 1400 ? "slide-right2" : ""}`}
              >
              В начале 1990-х годов Россия пережила социально-экономический кризис, 
              который особенно сказался на отраслях, связанных с Флотом. В этих условиях
               капитан 3 ранга Михаил Ненашев продемонстрировал «инициативу снизу».
               Он предложил идею 
               
                <span className="boldText"> организовать празднование 300-летия ВМФ России </span> 
               
                на
               государственном уровне в 1996 году. В 1991 году в Москве группа офицеров ВМФ,
                речников, рыбопромысловиков, учёных, преподавателей и судостроителей создала 
                Общественный Совет по подготовке празднования 300-летия Российского Флота. М. П. Ненашев 
                был избран председателем этого совета. Они надеялись, что объединение усилий общества и
                 государства вокруг празднования 300-летия Российского Флота поможет стране выйти из
                  экономических трудностей, как Пётр I использовал флот для подъёма России в число великих держав
              </p>
  
              <p
                className={`box2 ${scrollPosition > 1500 ? "slide-right2" : ""}`}
              >
               <span className="boldText">В 1997 году</span>  на искусственном острове у разделения Москва-реки и Водоотводного канала,
                 напротив воссоздаваемого Храма Христа Спасителя, по заказу Правительства Москвы 
                 
                 <span className="boldText"> был 
                 воздвигнут памятник </span> 
                 
                 
                 «В ознаменование 300-летия Российского Флота». Этот монумент
                  стал одним из самых высоких в России, его высота составляет 98 метров. Проект
                   памятника был создан Зурабом Константиновичем Церетели. Работы по монтажу 
                   памятника проводили 120 монтажников под руководством ведущего геодезиста
                    Валерия Маханова и ведущего бригадира Владимира Максимова. Памятник
                     был открыт 5 сентября 1997 года <span className="boldText">в рамках масштабного празднования 850-летия Москвы.</span> 
              </p>
  
              <p
                className={`box2 ${scrollPosition > 1600 ? "slide-right2" : ""}`}
              >
                Памятник Петру I в Москве воспринимается как своего рода аналог петербургскому памятнику 
                Петру («Медный всадник»), также являющемуся символическим литературным объектом.
              </p>
            </div>

            
          </aside>
        </div>
      </div>
    </section>
  );
};

export default FourthBlock;
