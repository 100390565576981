import React from "react";
import fifthBlockImg from "../../assets/images/cereteli.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FifthBlock = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <section className="fifthblock author" ref={ref}>
      <div className="container">
        {inView && (
          <>
            <div className="fifthblock__wrapper">
              <motion.aside
                className="fifthblock__left"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,   
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <h2>ОБ АВТОРЕ ПАМЯТНИКА</h2>

                <div className="text-wrap-yellow">

                <p>
                <span className="boldText">Зураб Константинович Церетели</span> - известный художник-монументалист, президент Российской академии художеств с 1997 года и директор Московского музея современного искусства с 1999 года. Он родился в Тбилиси в 1934 году и окончил Тбилисскую академию художеств в 1958 году. С 1970 года по 1980 год З.К. Церетели был главным художником МИД СССР. Он принимал участие в создании Мемориального комплекса на Поклонной горе в 1995 году и разработал художественное решение обновленной Манежной площади в 1997 году. Церетели также известен как автор монумента Победы на Поклонной горе и памятника "300 лет Российского Флота" на Москве-реке. Он был главным художником Олимпиады в Москве в 1980 году и удостоен звания Героя Социалистического Труда. Церетели имеет почетные звания народного художника СССР, народного художника России и народного художника Грузии. <span className="boldText">Он является членом ряда академий и профессором.</span>                  </p>
                 
                 
                  <p>
                    
                  В 1964 году Церетели прошел курс обучения во Франции, где общался с Пабло Пикассо и Марком Шагалом. В 1965-1967 годах он был главным художником-оформителем при строительстве курортного комплекса в Пицунде и наладил массовое производство смальты для мозаичных работ. В 1970-1972 годах Церетели создал ряд мозаичных и витражных композиций в Тбилиси. В 1973 году он стал автором монументального ансамбля детского курортного городка в Адлере.
                     </p>
                     
                     
                     <p>
                      
                     <span className="boldText">В 2003-2010 годах Церетели установил множество памятников в Москве, других городах России и мира</span> , в том числе памятники основателю академии художеств Ивану Шувалову в Санкт-Петербурге, княгине Ольге в Пскове, Оноре де Бальзаку в городе Агда во Франции, генералу Шарлю де Голлю в Москве, Александру Пересвету, герою Куликовской битвы, в Борисоглебске, президенту Чеченской республики Ахмаду Кадырову в Грозном.




                </p>
                </div>

              </motion.aside>
              <motion.aside
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                className="fifthblock__right"
              >
                <img src={fifthBlockImg} alt="fifth block" />
              </motion.aside>
                </div>



            <p>
            Зураб Константинович Церетели - член Общественной палаты РФ с 2005 года. Он удостоен звания Героя социалистического труда, имеет почетные звания народного художника СССР, народного художника России и народного художника Грузии. Скульптор является президентом Московского международного фонда содействия ЮНЕСКО, академиком Международной академии творчества, <span className="boldText">Президентом Российской академии художеств</span> , действительным членом Грузинской академии наук, профессором Брокпортского университета изящных искусств и членом-корреспондентом Французской академии изящных искусств.
            </p> 
          </>
        )}
      </div>
    </section>
  );
};

export default FifthBlock;
