import React, { useEffect, useState } from "react";
import thirdBlockImg from "../../assets/images/peter1-flot.jpg";

const ThirdBlock = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="thirdblock">
      <div className="container">
        <div className="thirdblock__wrapper">
          <aside className="thirdblock__left">
            <h2 className={`box ${scrollPosition > 3400 ? "slide-right" : ""}`}>Ключевые реформы Императора</h2>
            <img
              className={`box ${scrollPosition > 3450 ? "slide-right" : ""}`}
              src={thirdBlockImg}
              alt="third block"
            />
          
          </aside>
          <aside className="thirdblock__right">
<div className="text-wrap-yellow">
  
                <p className={`box2 ${scrollPosition > 3550 ? "slide-right2" : ""}`}>
              Император Петр I был выдающимся лидером, который внедрил эффективные реформы и 
              запомнился своей стране умелым управленцем. В его правление Россия превратилась в имперскую державу
               с модернизированным административным устройством, а страна получила новый тип светской культуры.
              К ключевым реформам можно отнести:
              </p>
  
  
              <p className={`box2 ${scrollPosition > 3700 ? "slide-right2" : ""}`}>

                <p className="boldText">Строительство флота:</p>
               Петр I овладел побережьем моря и создал отечественную промышленность. 
              В 1703 году была заложена первая русская военно-морская база в Кронштадте, а в
               1705 году был заложен первый корабль, построенный в России.
  
              </p>
  
              <p className={`box2 ${scrollPosition > 3800 ? "slide-right2" : ""}`}>

              <p className="boldText">Реформирование армии:</p>

               Петр I начал вооружать пехоту кремневыми ружьями со
               штыком единого калибра и шпагами. Были разработаны новые образцы 
               артиллерийских орудий и боеприпасов. В 1697 году стрелецкие полки,
                являвшиеся основой армии, были расформированы, и в ихместе была
                 создана многочисленная постоянная армия, которая имела значительное преимущество перед европейскими наемными войсками.
  
              </p>
  
              <p className={`box2 ${scrollPosition > 3900 ? "slide-right2" : ""}`}>
              <p className="boldText">Изменение системы военного обучения:</p>

               С 1699 года
               обучение солдат и офицеров стали проводить по единому 
               строевому уставу, акцент делался на постоянную военную подготовку. 
               В 1700 году была открыта первая военная школа для офицеров, а в 1715 году – военно-морская Академия в Санкт-Петербурге.
  
              </p>
  
              <p className={`box2 ${scrollPosition > 4000 ? "slide-right2" : ""}`}>
              <p className="boldText">Реформы государственного управления:</p>

                
               Петр I сплотил дворянство, которое превратилось в 
               единое сословие с собственными правами и обязанностями.
                При Петре I оно превратилось в единое сословие с едиными правами и обязанностями.
  
              </p>
  
              <p className={`box2 ${scrollPosition > 4100 ? "slide-right2" : ""}`}>
              <p className="boldText">Введение новой азбуки</p>

              В 1708 году была введена новая азбука, которая основана на латинской графике и была
               разработана немецким педагогом Иоганном Амманским. Это была первая азбука, которая 
               использовалась для обучения грамоте в России, и она оставалась в употреблении до 1917 года. 
               Введение этой азбуки помогло упростить обучение грамоте и способствовало распространению грамотности в стране.
              </p>
</div>
          </aside>
        </div>
      </div>
    </section>
  );
};

export default ThirdBlock;
